import React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import avatarAyman from 'images/ayman.jpg'
import avatarPierre from 'images/pierre.jpeg'
import avatarZoe from 'images/zoe.jpeg'

import Container from 'components/Container';

const mock = [
    {
        name: 'Pierre Arbajian, PhD',
        title: 'Chief AI Architect',
        avatar: avatarPierre,
        about:
            'Pierre is our Senior Data Scientist and Chief AI Architect, bringing a wealth of experience in all things data and AI. He is passionate about studying and tinkering with LLMs, recognizing that AI holds the potential to revolutionize the world, he strives to make Pillar1 a relevant player in helping clients effectively navigate and adopt this transformative technology.',
    },
    {
        name: 'Ayman Hajja, PhD',
        title: 'Chief Technology Officer',
        avatar: avatarAyman,
        about:
            'Serves as our Chief Technology Officer, known for developing and deploying cutting-edge, highly complex solutions. With profound expertise in both theory and practice, Ayman leads implementations with precision and unwavering commitment to quality. He firmly believes in a methodical and careful approach, emphasizing thorough development, testing, and implementation with insightful precision for every project and product.',
    },
    {
        name: 'Yuehua (Zoe) Duan, Ph.D',
        title: 'Senior Data Scientist',
        avatar: avatarZoe,
        about:
            'Serves as our Senior Data Scientist. With a focus on customer retention and satisfaction use cases, Zoe specializes in building cutting-edge recommender systems that mitigate customer attrition. She is versed in the extraction data patterns from large datasets and translating them into actionable recommendations. Zoe is passionate about LLM and data visualization. This fuels her commitment to exploring innovative approaches in LLM and delivering superior data visualizations that communicate complex insights with clarity and impact.',
    }
];

const WithSimpleCards = () => {
    const theme = useTheme();
    return (
        <Container>
            <Box>
                <Box marginBottom={4}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                        }}
                        gutterBottom
                        color={'text.secondary'}
                        align={'center'}
                        fontWeight={700}
                    >
                        Our team
                    </Typography>
                    <Typography fontWeight={700} variant={'h4'} align={'center'}>
                        Trust the professionals
                    </Typography>
                </Box>
                <Grid container spacing={2} alignItems="stretch">
                    {mock.map((item, i) => (
                        <Grid item xs={12} sm={6} md={4} key={i} style={{display: 'flex', flexDirection: 'column'}}>
                            <Box
                                component={Card}
                                boxShadow={2}
                                sx={{
                                    textDecoration: 'none',
                                    transition: 'all .2s ease-in-out',
                                    '&:hover': {
                                        transform: `translateY(-${theme.spacing(1 / 2)})`,
                                    },
                                    height: '100%',
                                }}
                            >
                                <CardContent>
                                    <Box
                                        component={Avatar}
                                        src={item.avatar}
                                        height={80}
                                        width={80}
                                    />
                                    <Box marginTop={4}>
                                        <ListItemText primary={item.name} secondary={item.title}/>
                                        <Typography variant={'subtitle2'} color={'text.secondary'}>
                                            {item.about}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
};

export default WithSimpleCards;
